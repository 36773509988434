/* src/components/Card.css */
.card {
    max-width: 700px;
    padding: 20px;
    margin-top: 30px;
    background-color: #fff;
    text-align: center;
}

.borda-card {
    position: relative;
    width: 600px;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
    border: 2px
}

.titulo {
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
}

.conteudo {
    flex: 1;
    background-color: rgb(245, 244, 244);
}

.sessao {
    font-family: 'Inter', sans-serif;
    font-style: initial;
    text-align: center;
    padding-left: 40px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.106em;
    text-align: left;
    color: #000;
}

.interna {
    padding-left: 0px !important;
}

/* Formulário */
.buton-add-destino {
    width: 330px;
    height: 50px;
    gap: 8px;
    flex-shrink: 0;
    background: #000;
    max-width: 100%;
    margin-top: 80px;
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
}

/* Formulário */
.buton-continuar {
    width: 200px;
    height: 60px;
    gap: 8px;
    flex-shrink: 0;
    background: #E224A9;
    max-width: 100%;
    margin-top: 30px;
}
.botao {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.borda-form {
    padding: 10px 40px 0px 40px;
}

.margin-fields {
    padding-top: 20px;
}

.linha-vertical {
    width: 102px;
    top: 1708px;
    left: 960px;
    border: 1px;
    background: #BEBEBE;
    border: 1px solid #BEBEBE
}

.linha-horizontal {
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 25px 5px 25px 5px;
    top: 813px;
    left: 624px;
    border: 1px;
    border: 1px solid #DDDDDD;
    color: #DDDDDD;
}

.centralizar {
    display: block;
    margin: 30px auto 30px auto;
}

.tituloLabel {
    width: 117px;
    height: 25px;
    top: 1727px;
    left: 671px;
}
  
.item-left {
    text-align: left;
}

.item-right {
    text-align: left;
    margin-right: 30px;
}

.padding-top {
    /* margin-top: 12px; */
}

.titulo-destino {
    color: #16251E99;
    font-size: 12px;
}

.button-sessao {
    margin-top: 20px;
}

.modal-content-data {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
}

.close-data {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

.custom-date-range .rdrDayPickerWrapper .rdrDateRangeWrapper .rdrDateRangePickerInput .rdrDateRangePickerInputRange .rdrDateRangePickerInputRangeRight .rdrDateRangePickerInputDate .rdrDateRangePickerInputDateDisplay span {
    color: white; /* Cor do texto */
    background-color: "#FEE9F7"; /* Cor de fundo */
}
  

.titulo-daterange {
    font-family: 'Inter';
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 1px;
    text-align: left;
    color: #00000099;
    height: 32px;
}

.ok-date-range {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    width: 21px;
    height: 24px;
    top: 889.5px;
    left: 16621px;
    color: #E224A9;
    cursor: pointer;
    margin-left: 20px;
    text-align: right;
}

.cancel-date-range {
    width: 59px;
    height: 24px;
    top: 889.5px;
    left: 16542px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: #695DA6;
    cursor: pointer;
}

.grid-right {
    display: grid;
    grid-template-columns: 4fr 1fr;
}

.centralizar-date-range {
    display: 'flex';
    justify-content: 'center'; 
    align-items: 'center'; 
    height: '100vh';
}

.alerta-error {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: -30px;
    color: #E44030;
}

/* Estilos específicos para telas de desktop (largura mínima de 768px) */
@media screen and (min-width: 768px) {
    .delete-destino {
        margin-top: -32px;
        margin-left: 480px;
        font-size: 1px;
        cursor: pointer;
    }

    .grid-two-equal-columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px; /* Espaçamento entre os itens */
    }

    .grid-two-columns-left {
        display: grid;
        grid-template-columns: 1fr 3fr; /* 1/4 para a primeira coluna, 3/4 para a segunda coluna */
        grid-gap: 16px; /* Espaçamento entre os itens */
    }

    .grid-two-columns-right {
        display: grid;
        grid-template-columns: 3fr 1fr; /* 1/4 para a primeira coluna, 3/4 para a segunda coluna */
        grid-gap: 16px; /* Espaçamento entre os itens */
    }

    .icon {
        font-size: 24px;
        align-content: flex-start;
    }

    .subtitulo-destino {
        font-size: 15px;
        margin: 5px -30px;
        cursor: pointer;
    }

    .vertical-divider {
        height: 110%;
        margin-top:5px;
        border-left: 1px solid #ccc;
    }

    .card-pais{
        flex-shrink: 0;
        width: 100%;
        height: 70px;
        margin: 30px 0px 50px;
        border-radius: 15px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px #00000040;
    }

    .local-date {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-gap: 16px;
    }

    .icon-and-text {
        flex-direction: column;
        align-items: center;
    }

    .padding-left {
        margin-left: 40px;
    }

    /* Estilo do modal */
    .modal-data {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1;
        margin: 2% 5%;
        top: 0;
        background-color: rgba(0,0,0,0.4);
    }
}

/* Estilos específicos para telas de mobile (largura máxima de 767px) */
@media screen and (max-width: 767px) {
   
    .delete-destino {
        margin-top: -108px;
        margin-left: 88%;
        font-size: 1px;
        cursor: pointer;
    }

    .grid-two-equal-columns {
        margin-bottom: 30px;
    }

    .grid-two-columns-left {
        margin-bottom: 20px;
    }

    .grid-two-columns-right {
        margin-top: -20px;
    }

    .separar-input-group {
        margin-bottom: 20px;
    }

    .icon {
        font-size: 2px;
        margin-left: -4px;
        width: 30px;
    }

    .subtitulo-destino {
        font-size: 12px;
        margin: -20px 3px 50px 30px;
        cursor: pointer;
    }

    .card-pais{
        flex-shrink: 0;
        width: 100%;
        height: 120px;
        margin: 30px 0px 50px;
        border-radius: 15px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px #00000040;
    }

    .sessao {
        font-family: 'Inter', sans-serif;
        font-style: initial;
        text-align: center;
        padding-left: 50px;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.106em;
        color: #000;
    }

    .local-date {
        align-items: center;
    }

    .padding-left{
        margin-left: 20px;
    }
    
    .padding-mobile {
        margin-left: 20px;
        margin-top: -30px;
    }

    /* Estilo do modal */
    .modal-data {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1;
        margin: 15% 5%;
        width: 250px;
        top: 0;
        background-color: rgba(0,0,0,0.4);
    }
}