.body-final {
    flex: 1 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-final {
    background-color: #ADE555;
}

.container-final {
    position: relative;
    width: 100vw;
    height: 100vh;
    
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
}

.centralizada {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    flex-shrink: 0;
}

.buton-finalizar {
    width: 260px;
    height: 50px;
    border-radius: 11px;
    background-color: transparent;
    border: 2px solid #E224A9;
    display: block;
    margin: 0 auto;
    cursor: pointer;
}

.alert {
    justify-content: center;
    align-items: center;
    top: 853px;
    left: 816px;
    color: #F79733;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0em;
}

.text-button-final {
    color: #E224A9;
    line-height: normal;
    margin: 0 auto;
    max-width: 100%;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
}

input::placeholder {
    color: #101010;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 10px;
    transform: translateY(6px);
    font-weight: bold;
}

.label-error {
    margin-left: -260px;
    color: #7D7D7D;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    position: absolute;
    margin-top: 20px;
    padding: 0px 64px;
    z-index: 1;
}

/* Estilos específicos para telas de desktop (largura mínima de 768px) */
@media screen and (min-width: 768px) {
    .imagem-centralizada {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        width: 280px;
        height: 138px;
        flex-shrink: 0;
    }

    .borda-card-final {
        padding: 30px;
        width: 500px;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
        display: block;
        margin: 0 auto;
    
        justify-content: center;
        align-items: center;
    }

    .titulo-final {
        color: #00CC74;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 36px;
        font-style: normal;
        padding: 0px 50px;
        font-weight: 600;
        letter-spacing: 0em;
        line-height: 39.624px; /* 99.06% */
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .descricao-final {
        color: #000;
        font-family: 'Inter', sans-serif;
        width: 571px;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
    
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        flex-shrink: 0;
    }
    
    .sub-descricao {
        top: 669px;
        left: 772px;
        font-family: 'Inter';
        font-weight: 400;
        line-height: 27px;
        text-align: center;
    }

    .contato {
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        display: block;
        padding: 10px;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
    }
}

/* Estilos específicos para telas de mobile (largura máxima de 767px) */
@media screen and (max-width: 767px) {
    .imagem-centralizada {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        width: 280px;
        flex-shrink: 0;
        margin-top: 30px;
    }

    .borda-card-final {
        width: 90%;
        height: auto;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
        display: block;
        margin: 0 auto;
    
        justify-content: center;
        align-items: center;
    }

    .titulo-final {
        color: #00CC74;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 26px;
        font-style: normal;
        padding: 0px 60px;
        font-weight: 600;
        letter-spacing: 0em;
        line-height: 39.624px; /* 99.06% */
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .descricao-final {
        color: #000;
        font-family: 'Inter', sans-serif;
        width: 571px;
        text-align: center;
        font-size: 15px;
        font-weight: 700;

        display: block;
        margin: 0 auto;
        max-width: 80%;
        max-height: 100%;
        flex-shrink: 0;
    }
    
    .sub-descricao {
        top: 669px;
        left: 772px;
        font-family: 'Inter';
        font-weight: 400;
        line-height: 27px;
        text-align: center;
        padding: 10px 20px;
    }

    .contato {
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        padding: 30px 60px;
    }
}