.iconI.open {
  transform: rotate(135deg);
}

.iconI.closed {
  transform: rotate(-45deg);
}

.ellipse-icon {
  width: 40px;
  height: 40px;
  color: #333;
  border-radius: 50%;
  background-color: #EBEBEB;
  cursor: pointer;
}

.button-transparent {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/* Estilos específicos para telas de desktop (largura mínima de 768px) */
@media screen and (min-width: 768px) {
  .accordion {
    margin-bottom: 30px;
  }

  .iconI {
    flex-direction: column;
    width: 10px;
    height: 10px;
    border-left: 2px solid #333;
    border-bottom: 2px solid #333;
    transition: transform 0.3s;
  
    position: absolute;
    top: 58px;
    left: 90.7%;
    border-top: 0px solid transparent;
    border-right: 0px solid transparent;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .accordion-item {
    margin-bottom: 30px;
  }
  
  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-radius: 5px;
  }
  
  .accordion-content {
    padding: 10px;
  }
}

/* Estilos específicos para telas de mobile (largura máxima de 767px) */
@media screen and (max-width: 767px) {
  .accordion {
    margin-bottom: 30px;
    width: 90%;
  }

  .iconI {
    flex-direction: column;
    width: 10px;
    height: 10px;
    border-left: 2px solid #333;
    border-bottom: 2px solid #333;
    transition: transform 0.3s;
  
    position: absolute;
    top: 58px;
    left: 85%;
    border-top: 0px solid transparent;
    border-right: 0px solid transparent;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .accordion-item {
    margin-bottom: 30px;
  }
  
  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-radius: 5px;
  }
  
  .accordion-content {
    padding: 10px;
  }

  .borda-card-cad-acc {
    width: 100% !important;
    align-items: center;
  }
}
