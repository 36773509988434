/* src/components/Card.css */  
.App-header {
  background-color: #ADE555;
}
  
.input {
  display: flex;
  width: 320px;
  height: 35px;
  padding: 1px 0px;
  max-width: 100%;
  margin-top: 30px;
}

.buton-enviar {
  width: 200px;
  height: 50px;
  padding: 16px 7px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 11px;
  background: #E224A9;
  border-color: #E224A9;
  margin: 0 auto;
  max-width: 100%;
  margin-top: 30px;
}

input::placeholder {
  color: #101010;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 10px;
  transform: translateY(6px);
  font-weight: bold;
}

.label-cpf {
  margin-left: -260px;
  color: #7D7D7D;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  position: absolute;
  margin-top: 20px;
  padding: 0px 64px;
  z-index: 1;
}

/* Estilos específicos para telas de desktop (largura mínima de 768px) */
@media screen and (min-width: 768px) {
  .titulo-home {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 39.624px; /* 99.06% */
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .descricao {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    padding: 30px 50px 0px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
  }

  .logo-home {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    width: 75%;
    height: auto;
    flex-shrink: 0;
    margin-top: 40px;
  }
  
  .card {
    box-sizing: border-box;
  
    position: absolute;
    left: 30.1%;
    right: 30.36%;
    top: 13.04%;
    bottom: 12.95%;
    background-color: aliceblue;
  
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  
  .borda-card-home {
    width: 500px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
  }

  .alert-home {
    justify-content: center;
    align-items: center;
    top: 853px;
    left: 816px;
    color: #E44030;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0em;
  }

  .contato-home {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }

  .text-button {
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: inherit;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto;
    max-width: 100%;
  }
}

/* Estilos específicos para telas de mobile (largura máxima de 767px) */
@media screen and (max-width: 767px) {
  .titulo-home {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 39.624px; /* 99.06% */
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .descricao {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    padding: 20px 60px 0px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .logo-home {
    display: block;
    margin: 0 auto;
    margin-top: -130px;
    max-width: 100%;
    width: 80%;
    height: 138px;
    flex-shrink: 0;
  }

  .borda-card-home {
    flex-shrink: 0;
    width: 90%;
    margin: 0px 20px 0px 20px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
  }

  .alert-home {
    justify-content: center;
    align-items: center;
    top: 853px;
    left: 816px;
    color: #E44030;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 80px;
    letter-spacing: 0em;
  }

  .contato-home {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }

  .text-button {
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: inherit;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto;
    max-width: 100%;
  }
}