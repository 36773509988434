.button-radio {
  margin: 40px 0px 20px 0px;
  width: 280px;
  height: 50px;
  border-radius: 15.45px;
  color: linear-gradient(0deg, #CCCCCC, #CCCCCC),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 2.06px solid #CCCCCC;
  box-shadow: 0px 4.120235919952393px 10.300589561462402px 0px #00000040;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro semi-transparente */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Z-index alto para garantir que o overlay esteja acima de outros elementos */
}
  
.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
}
  
  
  .modal-title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
  }

  .centralizar {
    justify-content: center;
    top: 30%;
  }

  .pin-button {
    text-align: center;
  }

  .title-button {
    text-align: center;
    font-size: 20px;
  }
  
  .modal-content {
    margin-bottom: 20px;
  }
  
  .modal-button {
    justify-content: center;
    background-color: #E224A9;
    color: #fff;
    border: none;
    border-radius: 30px;
    height: 50px;
    width: 160px;
    gap: 8px;
    margin: 40px 10% 10px 25%;
    padding: 10px 15px;
    cursor: pointer;

    font-family: 'Inter';
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;

  }
  
  .modal-button:hover {
    background-color: #b81482;
  }

  /* App.css */

.custom-dropdown {
    margin: 20px;
}

.custom-select {
    width: 150px;
    height: 40px;
}

.custom-select:hover {
    border-color: #999;
}

.linha-select {
    width: 235px;
    top: 113px;
    border: 1.03px;
    color: #BEBEBE;
    border: 1.03px solid #BEBEBE;
}

/* Estilos específicos para telas de desktop (largura mínima de 768px) */
@media screen and (min-width: 768px) {
  /* Modal.css */
  .modal {
    position: fixed;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    max-height: 90%;
  }
}

/* Estilos específicos para telas de mobile (largura máxima de 767px) */
@media screen and (max-width: 767px) {
  /* Modal.css */
  .modal {
    position: fixed;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    max-height: 90%;
  }
}
  
  