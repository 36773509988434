body, html {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.cabecalho {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
}

.bottom-final {
  margin-top: -30px;
  width: 200px;
  height: 65px;
  border-radius: 30px;
  gap: 8px;
  background-color: #FFE603;
}

.textFinal {
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: black !important;
}

.custom-date-range .rdrInRange {
  background-color: #FEE9F7;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: black !important;
}

.custom-date-range .rdrEndEdge {
  background-color: #E224A9;
}
.custom-date-range .rdrStartEdge {
  background-color: #E224A9;
}

.rdrDateDisplayWrapper {
  /* display: none; */
}

/* Estilos específicos para telas de desktop (largura mínima de 768px) */
@media screen and (min-width: 768px) {
  .body {
    flex: 1;
    padding-top: 50px;
    width: 100%;
  }

  .header {
    background-color: #00CC74;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }

  .conteudo-cad {
    flex: 1;
    background-color: rgb(245, 244, 244);
  }

  .logo-cad {
    max-width: 100%;
    max-height: 100%;
  }

  .logo {
    width: 330px;
    height: 159px;
    top: 56px;
    left: 678px;
    padding-bottom: 10px;
  }

  .titulo {
    padding-top: 20px;
    font-family: 'Inter';
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
  }

  .borda-card-cad {
    position: relative;
    width: 600px;
    border-radius: 20px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
    border: 2px;
    background-color: #fff !important;
  }

  .corpo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .justify-accordion {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Estilos específicos para telas de mobile (largura máxima de 767px) */
@media screen and (max-width: 767px) {
  .body {
    flex: 1;
    padding-top: 30px;
    width: 100%;
  }

  .padding-top {
    padding-top: 0px;
  }

  .header {
    background-color: rgb(245, 244, 244);
    width: 100%;
  }

  .conteudo-cad {
    flex: 1;
    background-color: rgb(245, 244, 244);
  }

  .titulo {
    padding-top: 20px;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
  }

  .logo-cad {
    max-width: 100%;
    max-height: 100%;
  }

  .logo {
    width: 210px;
    padding: 20px;
  }

  .borda-card-cad {
    position: relative;
    width: 90%;
    border-radius: 20px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
    border: 2px;
    background-color: #fff;
  }

  .corpo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .justify-accordion {
    padding-left: 5%;
  }
}